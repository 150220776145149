import React from "react";
import MainHeader from "../../common/header/MainHeader";
import AboutUs from "../../Components/AboutUs/AboutUs";
import Blogs from "../../Components/Blogs/Blogs";
import Feature from "../../Components/Feature/Feature";
import MainFooter from "../../Components/Footer/MainFooter";
import Hero from "../../Components/Hero/Hero";
import OurPretner from "../../Components/OurPretner/OurPretner";
import PricingTable from "../../Components/PricingTable/PricingTable";
import ProductAnalytics from "../../Components/ProductAnalytics/ProductAnalytics";
import WorksArea from "../../Components/WorksArea/WorksArea";

const Merp = () => {
  return (
    <div>
      <MainHeader />
      <Hero />
      <Feature />
      <AboutUs />
      <ProductAnalytics />
      <WorksArea />
      {/* <Faq /> */}
      <PricingTable />
      <OurPretner />
      {/* <ProductReview /> */}
      <Blogs />
      <MainFooter />
    </div>
  );
};

export default Merp;
