import React from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import image1 from "./img/01.png";
import image2 from "./img/02.png";
import image3 from "./img/03.png";
import image4 from "./img/04.png";
import image5 from "./img/05.png";
import image6 from "./img/06.png";
const OurPretner = () => {
  const settings = {
    dots: false,
    infinite: true,
    autoPlay: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,

          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  return (
    <div id="clients">
      <div className="our-pertner-main-area-wrap">
        <Container>
          <div className="our-pertner-inner-area-wrap">
            <div className="our-pertner-title">
              <h6>High-impact teams rely on Xelerator</h6>
            </div>
            <div className="our-pertner-content">
              <div className="">
                <Slider {...settings}>
                  <div className="pertner-slider-single-item">
                    <img src={image1} alt="" />
                  </div>
                  <div className="pertner-slider-single-item">
                    <img src={image2} alt="" />
                  </div>
                  <div className="pertner-slider-single-item">
                    <img src={image3} alt="" />
                  </div>
                  <div className="pertner-slider-single-item">
                    <img src={image4} alt="" />
                  </div>
                  <div className="pertner-slider-single-item">
                    <img src={image5} alt="" />
                  </div>
                  <div className="pertner-slider-single-item">
                    <img src={image6} alt="" />
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default OurPretner;
