import React from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import AgencyDistributors from "./WorkProcess/AgencyDistributors";
import B2CDistributors from "./WorkProcess/B2CDistributors";
import Distributors from "./WorkProcess/Distributors";

const WorksArea = () => {
  return (
    <div id="works">
      <div className="work-area-main-wrap">
        <Container>
          <div className="work-area-title">
            <p>How It Works</p>
            <h2>In Depth</h2>
          </div>
          <div className="work-area-content-wrap">
            <Tabs defaultActiveKey="B2BUnit" id="work" className="mb-3">
              <Tab eventKey="B2BUnit" title="B2B Unit">
                <Distributors />
              </Tab>
              <Tab eventKey="B2CUnit" title="B2C Unit">
                <B2CDistributors />
              </Tab>
              <Tab eventKey="AgencyUnit" title="Agency Unit">
                <AgencyDistributors />
              </Tab>{" "}
            </Tabs>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default WorksArea;
