import React, { useState } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import dataAnalytix from "./img/data-analyticx.png";
import { productAnalyticsData } from "./productAnalyticsData";
const ProductAnalytics = () => {
  const [imageLink, setImageLink] = useState(dataAnalytix);
  const handleImageLink = (imageLink) => {
    setImageLink(imageLink);
  };
  console.log(imageLink);
  return (
    <div>
      <div className="product-analytics-main-area-wrap">
        <Container>
          <div className="product-analytics-title">
            <p>Product Features</p>
            <h2>Competition?</h2>
            <h3>Make that irrelevant</h3>
          </div>
          <Row>
            <Col lg={6} className="my-auto">
              <div className="product-analytics-accordion-left">
                <Accordion defaultActiveKey="1">
                  {productAnalyticsData.map((item, index) => (
                    <Accordion.Item eventKey={item.id} key={index}>
                      <Accordion.Header
                        onClick={() => handleImageLink(item?.img)}
                      >
                        {item.title}
                      </Accordion.Header>
                      <Accordion.Body className="desktop-hide-mobile-show-area">
                        <img src={imageLink} alt="" />
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            </Col>
            <Col lg={6}>
              <div className="product-analytics-right-area">
                {imageLink ? (
                  <img src={imageLink} alt="" />
                ) : (
                  <img src={imageLink} alt="" />
                )}
                {/* <img src={imageLink} alt="" />
                <img src={productAnaImg} alt="" /> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ProductAnalytics;
