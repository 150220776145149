import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import featureImg from "./feature.png";
const Feature = () => {
  return (
    <div>
      <div className="product-feature-main-area-wrap">
        <Container>
          <div className="product-feature-title">
            <p>Product Features</p>
            <h2>Make more out of your data</h2>
          </div>
          <div className="product-inner-area-wrap">
            <Row>
              <Col lg={3} md={6}>
                <div className="single-product-feature left">
                  <div className="product-feature-icon">
                    <i class="bx bxs-objects-horizontal-right"></i>
                  </div>
                  <div className="product-feature-content">
                    <h4>Data analytics</h4>
                  </div>
                </div>
                <div className="single-product-feature left">
                  <div className="product-feature-icon">
                    <i className="fas fa-chart-line"></i>
                  </div>
                  <div className="product-feature-content">
                    <h4>Industry insights</h4>
                  </div>
                </div>
                <div className="single-product-feature left">
                  <div className="product-feature-icon">
                    <i class="bx bx-signal-4"></i>
                  </div>
                  <div className="product-feature-content">
                    <h4>Predictive modeling</h4>
                  </div>
                </div>
              </Col>
              <Col lg={6} className={"mobile-display-none-feature-image"}>
                <div className="single-product-feature middle">
                  <img src={featureImg} alt="" />
                </div>
              </Col>
              <Col lg={3} md={6}>
                <div className="single-product-feature right">
                  <div className="product-feature-icon">
                    <i class="bx bx-scatter-chart"></i>
                  </div>
                  <div className="product-feature-content">
                    <h4>Cross industry collab</h4>
                  </div>
                </div>
                <div className="single-product-feature right">
                  <div className="product-feature-icon">
                    <i class="bx bxs-cube-alt"></i>
                  </div>
                  <div className="product-feature-content">
                    <h4>Product - service prognosis</h4>
                  </div>
                </div>
                <div className="single-product-feature right">
                  <div className="product-feature-icon">
                    <i class="bx bx-cross"></i>
                  </div>
                  <div className="product-feature-content">
                    <h4>Future proof marketing tool</h4>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Feature;
