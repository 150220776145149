import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { TruncateLines } from "react-truncate-lines";
export const postData = [
  {
    id: "7",
    icon: "1655196575416-7.png",
    title: "RE Sell out: ICONOMY",
    content: ` Unfolding our expertise in today’s competitive fronts of real estate world need innovative disruptions that connect people with ample choice of lifestyle items. At RANCON developments, we brought the startegic consultancies to bring quantified results for 'shelved-stocks' to immediate sell out- otherwise, these would have started to bleed profit. This project kicks off from a perspective of connecting dots in this sector which eventually moved towards more option-centric, even if the brick and mortar is built solid.`,
    author: "Nazmus Shakib",
    link: "https://maacsolutions.com/insight-post-7",
    date: "Jan 7 2022",
  },
  {
    id: "8",
    icon: "1655197107497-8.png",
    title: "Business case: Lamborghini in Myanmar",
    content: `As one of the most exciting liberalizations in recent times, Myanmar, coupled with its vast reserve of natural resources is expected to see huge foreign investment in the coming few years. With an expected Economic Growth of 8%, the effects of this are already visible. Myanmar has been appointed to chair ASEAN in 2014. Multilateral financial institutions and foreign governments are increasing aid and loosening sanctions against Myanmar.`,
    author: "Nazmus Shakib",
    link: "https://maacsolutions.com/insight-post-8",
    date: "Jan 8 2022",
  },
  {
    id: "9",
    icon: "1655197140888-9.png",
    title: "Two wheeler market synopsis: 2018-19",
    content: `Bangladesh-the emerging tiger of Asia is expecting high growth in the two wheeler industry. The next boom cycle is expetcted to propel by Govt. interventions to move from assembling to a fullfledged manufacturing, where manufacturers get 'tax benefits.`,
    author: "Nazmus Shakib",

    link: "https://maacsolutions.com/insight-post-9",
    date: "Jan 9 2022",
  },

  {
    id: "10",
    icon: "1655197177892-10.png",
    title: "MAAC LMS: Enabling & Empowering digital learning",
    content: `Businesses need to manage their resources wisely if they are going to succeed. In this fast-paced business world, learning and developing skills is a must to maintain growth and stay ahead of the curve. One of the best-proven methods in the last 10 years globally has been integrating learning with technology so that the process is agile and adaptive for all users. `,
    author: "Nafisuzzaman",
    link: "https://maacsolutions.com/insight-post-10",
    date: "Jan 10 2022",
  },
];
const imageUrl = "https://staging-ddc.sgp1.digitaloceanspaces.com/";
const Blogs = () => {
  return (
    <div id="Testimonials">
      <div className="blog-main-area-wrap">
        <Container>
          <div className="blog-inner-area-wrap">
            <div className="blog-area-title">
              <p>New Resources</p>
              <h2>Xelerator stories</h2>
            </div>
            <div className="blog-area-content">
              <Row>
                {postData.slice(1, 4).map((blog, idx) => (
                  <Col key={idx} lg={4} sm={12} className="mb-3">
                    <a
                      href={blog.link}
                      target="_blank"
                      rel="noreferrer"
                      className="blog-single-item"
                    >
                      <div className="blog-top-image">
                        <img src={`${imageUrl}${blog.icon}`} alt="icon" />
                      </div>
                      <div className="blog-bottom-content">
                        <a href={blog.link} target="_blank" rel="noreferrer">
                          <h4>
                            <TruncateLines
                              lines={1}
                              ellipsis={<span>...</span>}
                            >
                              {blog.title}
                            </TruncateLines>
                          </h4>
                        </a>
                        <p>
                          <TruncateLines lines={2} ellipsis={<span>...</span>}>
                            {blog.content}
                          </TruncateLines>
                        </p>
                      </div>
                      <hr />
                      <div className="blog-content-footer">
                        <div className="blog-content-footer-left">
                          <i className="bx bx-user-circle"></i>
                          <span>{blog.author}</span>
                        </div>
                        <div className="blog-content-footer-right">
                          <i className="bx bx-time-five"></i>
                          <span>
                            <time dateTime={blog.date}>{blog.date}</time>
                          </span>
                        </div>
                      </div>
                    </a>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Blogs;
