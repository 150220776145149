import React from "react";
import { Container } from "react-bootstrap";
import footerLogo from "./aXs.svg";
const MainFooter = () => {
  return (
    <div>
      <div className="footer-main-area-wrap">
        <Container>
          <div className="footer-main-top-area">
            <img src={footerLogo} alt="" />
            <p>
              Want your data inputs navigating driven into a compelling brand
              insights too? Where a storyline to cater the best move ahead of
              competition?
            </p>
            <div className="footer-social">
              <a
                href="https://www.facebook.com/MAAC.Bangladesh"
                target="_blank"
                rel="noreferrer"
              >
                <i className="bx bxl-facebook"></i>
              </a>
              <a
                href="https://twitter.com/MAAC_Solutions"
                target="_blank"
                rel="noreferrer"
              >
                <i className="bx bxl-twitter"></i>
              </a>

              <a
                href="https://www.linkedin.com/company/market-access-analytics-and-consulting-maac/mycompany/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="bx bxl-linkedin"></i>
              </a>
            </div>
            <hr />
          </div>
          <div className="footer-bottom-copyright-area">
            <p>© Xelerator {new Date().getFullYear()}</p>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default MainFooter;
