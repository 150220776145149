import image4 from "./img/crossIndustry.png";
import image6 from "./img/futureProof.png";
import image2 from "./img/industr.png";
import image3 from "./img/map.png";
import image5 from "./img/product-service.png";
export const productAnalyticsData = [
  {
    id: 1,
    title: "Real-time analytics",

    img: image6,
  },
  {
    id: 2,
    title: "Industry insights ",

    img: image2,
  },
  {
    id: 3,
    title: "Predictive modeling",

    img: image3,
  },
  {
    id: 4,
    title: "Cross industry collab",

    img: image4,
  },
  {
    id: 5,
    title: "Product - service prognosis",

    img: image5,
  },
  // {
  //   id: 6,
  //   title: "Future proof marketing tool    ",

  //   img: image6,
  // },
];
