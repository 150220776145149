import React from "react";
import { Col, Row } from "react-bootstrap";
import image3 from "./img/agency.png";
import image2 from "./img/b2c-1.png";
import image1 from "./img/image-1.png";
const AgencyDistributors = () => {
  return (
    <div>
      <Row>
        <Col lg={4}>
          <div className="work-inner-single-items work-image-item-1">
            <img src={image1} alt="" />
            <h3>1. Start your free trial</h3>
          </div>
        </Col>
        <Col lg={4}>
          <div className="work-inner-single-items  work-image-item-2">
            <img src={image2} alt="" />
            <h3>2. Connect your product</h3>
          </div>
        </Col>
        <Col lg={4}>
          <div className="work-inner-single-items  work-image-item-3">
            <img src={image3} alt="" />
            <h3>3. Get data right in hand</h3>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AgencyDistributors;
