import React from "react";
import { Container } from "react-bootstrap";
import bgBottom from "./hero-bottom.png";
import fullBgMain from "./main-bg.png";
const Hero = () => {
  return (
    <div id="hero">
      <div className="hero-main-area-wrap">
        <img src={fullBgMain} alt="" />
        <Container>
          <div className="hero-inner-area-wrap">
            <div className="hero-top-area-content">
              <h2>
                Data center | Dynamic Data Aggregator <br />
              </h2>
              <h3>Datahub that transforms your next move</h3>
              <div className="hero-top-bottom-button-area ">
                <a
                  href="https://maacsolutions.com/course-demo-request?coursename=Xelerator"
                  target="_blank"
                  rel="noreferrer"
                >
                  Request for Demo
                </a>
                <a
                  href="https://maacsolutions.com/contact"
                  target="_blank"
                  rel="noreferrer"
                >
                  Connect
                </a>
              </div>
            </div>
            <div className="hero-bottom-area-image">
              <img src={bgBottom} alt="" />
            </div>
          </div>
        </Container>
      </div>
      {/* <hr /> */}
    </div>
  );
};

export default Hero;
